import "./styles/temp_splash.scss";
import addShims from "./utils/add_shims";

addShims();

// const $tempSplash = require(`./temp_splash.html`);

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length == 2)
    return parts
      .pop()
      .split(";")
      .shift();
}

// add app div to DOM
const APP_ID = "fluxx-up";
const $appDiv = document.createElement("div");
$appDiv.id = APP_ID;
document.body.insertBefore($appDiv, document.body.firstChild);

if (window.location.pathname === "/" && !getCookie("fluxx-current-user")) {
  window.location.replace("/login");
  //$appDiv.innerHTML = $tempSplash;
}

import(/* webpackChunkName: "up" */ "./up").then(upModule => {
  console.log("up.js loaded");
  upModule.default();
  console.log("up.js run");
});
